import './vendors/h5bp';

/* 
 * Mapa mediante la API de Google Maps
 */

function googleMaps() {
  
  // Ubicación LAT
  var lugar = new google.maps.LatLng(-31.740408, -60.519456);

  // Estilos
  var estilos = [ { "featureType": "landscape", "stylers": [ { "color": "#ffffff" } ] },{ "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#cb0202" } ] },{ "featureType": "water", "stylers": [ { "color": "#cb0202" } ] } ];

  // Opciones del mapa
  var mapOptions = {
    center: lugar,
    zoom: 15,
    styles: estilos,
    scrollwheel: false
  };

  // Crear mapa
  var map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);

  // Marcador
  var marker = new google.maps.Marker({
    position: lugar,
    icon: "../assets/media/location-icon.png",
    map: map,//lo asocio al mapa
    optimized: false
  });

  var infoWindow = new google.maps.InfoWindow({
      content: "<p style='text-align:center'>Pedro Echagüe 1027 <br> C.P. 3100 <br> Paraná | Entre Ríos</p><p>Coord: -31.740408, -60.519456</p>"
  });

  google.maps.event.addListener(marker, 'click', function () {
      infoWindow.open(map, marker);
  });


}


/* 
 * - img thumbnail
 */
function moveThumbnail() {

  $(".conten_mini img").on("click", function(){
    $("#slick-slide0"+$(this).data("imgid")).click();
  });

}

/* 
 * - Same Height in all boxes
 */
function maxHeightEntry() {
  "use strict";

  if( $(".boxed").length ) {

    // Obtener la mayor altura
    var maxHeight = 0;
    $(".boxed").each(function() {
      if ( $(this).height() > maxHeight ) {
        maxHeight = $(this).height();
      }
    });

    $(".boxed div").css("height", maxHeight);
  }

}

/* 
 * - img thumbnail
 */
 function allSliders() {

  $('.slider').slick ({
    arrows: true,
    dots: false,
    autoplay: true,
    speed: 500,
    fade: true,
    cssEase: 'linear'
  });

  $('.slider-cars').slick ({
    arrows: true,
    dots: true,
    autoplay: true,
    speed: 500,
    fade: true,
    cssEase: 'linear'
  });

  $('.responsive').slick({
    autoplay: true,
    dots: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.financiacion').slick({
    autoplay: true,
    dots: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

 }

 /* 
 * Envío del formulario de contacto
 *
 * - Ejecucion en: $(document).ready()
 */

function contactFormGo(){

  $("#contact-submit").on('click', function(e){//formulario de contacto
    e.preventDefault();

    var okflag=true;
    $('input,textarea').filter('[required]:visible').each(function(i, requiredField){
      if($(requiredField).val()==''){
        if(okflag==true)$('#msg-all').fadeIn().delay(5000).fadeOut();
        okflag=false; 
        $(requiredField).addClass("contact-error");
      }
      else $(requiredField).removeClass("contact-error");
    });

    if(okflag==true)
      $('#msg').addClass("backGload").fadeIn().load('contacto.php', {
        nombre: $('#contact-name').val(),
        telefono: $('#contact-tel').val(),
        telefono2: $('#contact-tel2').val(),
        email: $('#contact-email').val(),        
        mensaje: $('#contact-message').val()
      }, function(){
        $('input, textarea').val("");
        $('#msg').removeClass("backGload");
      });

  });

}

 /* 
 * Envío del formulario de vende tu auto
 *
 * - Ejecucion en: $(document).ready()
 */

function sellCarFormGo(){

  $("#sellcar-submit").on('click', function(e){//formulario de contacto
    e.preventDefault();

    var okflag=true;
    $('input,textarea').filter('[required]:visible').each(function(i, requiredField){
      if($(requiredField).val()==''){
        if(okflag==true)$('#msg-all').fadeIn().delay(5000).fadeOut();
        okflag=false; 
        $(requiredField).addClass("contact-error");
      }
      else $(requiredField).removeClass("contact-error");
    });

    if(okflag==true)
      $('#msg').addClass("backGload").fadeIn().load('contacto.php', {
        nombre: $('#sellcar-name').val(),
        telefono: $('#sellcar-tel').val(),
        telefono2: $('#sellcar-tel2').val(),
        email: $('#sellcar-email').val(),
        marca: $('#sellcar-label').val(),
        modelo: $('#sellcar-model').val(),
        ano: $('#sellcar-year').val(),
        km: $('#sellcar-km').val(),
        motor: $('#sellcar-engine').val(),
        mensaje: $('#sellcar-message').val()
      }, function(){
        $('input, textarea').val("");
        $('#msg').removeClass("backGload");
      });

  });

}


// $(document).foundation();
/* 
 * - Trigger functions on doc ready
 */
$(document).ready(function(){

  allSliders(); 
  moveThumbnail();
  maxHeightEntry();
  if($("#map-canvas").length){
    contactFormGo();
    googleMaps();
  } 
  if($(".sell-car-form").length) 
    sellCarFormGo();


});
